html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

#layout {
  display: flex;
  overflow: hidden;
  width: 100%;
}
